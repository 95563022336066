import React, { useState } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

const RadarChart: React.FC = () => {
    const [values, setValues] = useState({
      servantleader: 8,
      coach: 7,
      facilitator: 6,
      teacher: 5,
      mentor: 7,
      manager: 4,
      impedimentremover: 9,
      changeagent: 6
    });
  
    const data = {
      labels: [
        'Servant Leader',
        'Coach',
        'Facilitator',
        'Teacher',
        'Mentor',
        'Manager',
        'Impediment Remover',
        'Change Agent'
      ],
      datasets: [
        {
          label: 'Scrum Master Stances',
          data: [
            values.servantleader,
            values.coach,
            values.facilitator,
            values.teacher,
            values.mentor,
            values.manager,
            values.impedimentremover,
            values.changeagent
          ],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        } as DataSet
      ]
    };
  
    const options = {
      scales: {
        r: {
          min: 0,
          max: 10,
          ticks: {
            stepSize: 1,
          }
        }
      },
      plugins: {
        legend: {
          position: 'top' as 'top',
        }
      }
    };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: Number(e.target.value)
    });
  };

  return <div>
      <Radar data={data} options={options} />;
      <div style={{ maxHeight: "400px", background: "dimgray"}}>
        {data.labels.map((label, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-between"}}>
            <label>{label}({data.datasets[0].data[index]}): </label>
            <input
                type="range"
                name={label.toLowerCase().replace(' ', '')}
                value={data.datasets[0].data[index]}
                min="0"
                max="10"
                onChange={handleChange}
            />
            </div>
        ))}
      </div>
    </div>
};

export default RadarChart;
